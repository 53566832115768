import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { navigate, RouteComponentProps } from '@reach/router';
import Layout from '../../components/Layout';
import {
  useCollectionDataOnce,
  useDocumentOnce,
} from 'react-firebase-hooks/firestore';
import { graphql, useStaticQuery } from 'gatsby';
import { ContentfulTherapistEdge, MatchesQuery } from '../../graphql-types';
import { MatchResultsFirebase } from './MatchAuth';
import { IFluidObject } from 'gatsby-background-image';
import { UserTherapistCardProps } from '../../components/TherapistCard/UserTherapistCard';
import { MatchTherapistCard } from '../../components/TherapistCard/MatchTherapistCard';
import { TherapyTypes, UserMatchingRecommendation } from '../../utils/match';
import { darken, lighten } from 'polished';
import { theme } from '../../theme';
import Text from '../../reusecore/src/elements/Text/text';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth, getFirebaseFirestore } from '../../services/firebase';
import Box from '../../reusecore/src/elements/Box';
import Button from '../../reusecore/src/elements/Button/button';
import { intersection, shuffle } from 'lodash-es';

export interface MatchesPageProps extends RouteComponentProps {}

export const getMatchesInfoForCards = (
  matchResults: MatchResultsFirebase,
  allTherapistEdges: ContentfulTherapistEdge[]
): [UserTherapistCardProps[], boolean] => {
  const { resultsObject: results } = matchResults;
  const filteredMatchResults = results.filter(x => !x.dealBroken);
  const matchesInfo = filteredMatchResults
    .map<any>(x => {
      const pluckedTherapistInfo = allTherapistEdges.filter(
        y => x.therapistId === y.node.slug
      )[0];
      return {
        slug: pluckedTherapistInfo.node.slug,
        profileImageSrc: pluckedTherapistInfo.node.profilePhoto.fixed.src,
        score: x.dealBroken ? 50 : x.score,
        fullName: pluckedTherapistInfo.node.fullName,
        title: pluckedTherapistInfo.node.title,
        distanceMiles: x.distance,
        headline: pluckedTherapistInfo.node.headline.headline,
        pricePerSession: pluckedTherapistInfo.node.sessionPricing,
        therapyTypes: pluckedTherapistInfo.node.therapyTypes,
        specialisms: pluckedTherapistInfo.node.specialisms,
        sexuality: pluckedTherapistInfo.node.sexuality,
        gender: pluckedTherapistInfo.node.gender,
        ethnicity: pluckedTherapistInfo.node.ethnicity,
      };
    })
    .slice(0, 3)
    .sort((a, b) => b.score - a.score);

  return [
    matchesInfo,
    results.map<boolean>(x => x.dealBroken).reduce((a, b) => a && b),
  ];
};

const MatchesAuth: React.FunctionComponent<MatchesPageProps> = (): JSX.Element => {
  const {
    allContentfulTherapist: { edges: therapistData },
  } = useStaticQuery<MatchesQuery>(graphql`
    query Matches {
      allContentfulTherapist(filter: { currentlyAvailable: { eq: true } }) {
        edges {
          node {
            slug
            therapyTypes
            specialisms
            dateOfBirth
            sexuality
            gender
            ethnicity
            sessionPricing
            profilePhoto {
              fixed(width: 600, height: 400) {
                src
              }
            }
            fullName
            headline {
              headline
            }
            title
          }
        }
      }
    }
  `);

  const [user, loading, error] = useAuthState(getFirebaseAuth());

  const db = getFirebaseFirestore();

  const [userSnapshot, userLoading, userError] = useDocumentOnce(
    !db ? null : db.collection('users').doc(user.uid)
  );

  const [matchesSnapshot, matchesLoading, matchesError] = useCollectionDataOnce<
    MatchResultsFirebase
  >(
    !db
      ? null
      : db
          .collection('users')
          .doc(user.uid)
          .collection('matchResults')
          .orderBy('creationDate', 'desc')
  );

  const [topThreeMatchInfo, setTopThreeMatchInfo] = useState<any>(null);

  if (userLoading || matchesLoading) {
    return (
      <Container
        fluid
        className="w-100 vh-100 d-flex flex-column justify-content-center align-items-center position-absolute"
        style={{ backgroundColor: '#3d8371' }}
      >
        <div className="sk-bounce">
          <div className="sk-bounce-dot bg-white" />
          <div className="sk-bounce-dot bg-white" />
        </div>
      </Container>
    );
  }

  const [cardsInfo, noMatchesFound] = getMatchesInfoForCards(
    matchesSnapshot[0],
    therapistData as ContentfulTherapistEdge[]
  );

  const recommendations: UserMatchingRecommendation = matchesSnapshot[0]
    .recommendations || {
    specialisms: [],
    therapies: [],
    distance: { value: null, dealbreaker: null },
    pricing: { value: null, dealbreaker: null },
    demographic: {
      age: { value: [], dealbreaker: false },
      ethnicity: { value: [], dealbreaker: false },
      gender: { value: [], dealbreaker: false },
      sexuality: { value: [], dealbreaker: false },
    },
  };

  const cards = cardsInfo.map(x => (
    <Col key={x.slug} xs={12} lg={4} style={{ display: 'flex' }}>
      <MatchTherapistCard
        title={x.title}
        distanceMiles={x.distanceMiles}
        fullName={x.fullName}
        pricePerSession={x.pricePerSession}
        score={x.score}
        slug={x.slug}
        headline={x.headline}
        profileImageSrc={x.profileImageSrc}
        therapyTypes={intersection(x.therapyTypes, recommendations.therapies)}
        specialisms={intersection(x.specialisms, recommendations.specialisms)}
        demographics={[
          ...intersection(recommendations.demographic.sexuality.value, [
            x.sexuality,
          ]),
          ...intersection(recommendations.demographic.gender.value, [x.gender]),
          ...intersection(recommendations.demographic.ethnicity.value, [
            x.ethnicity,
          ]),
        ]}
      />
    </Col>
  ));

  const randomTherapistCards = shuffle(therapistData.slice(0, 10))
    .slice(0, 3)
    .map<JSX.Element>(x => (
      <Col key={x.node.slug} xs={12} lg={4} style={{ display: 'flex' }}>
        <MatchTherapistCard
          title={x.node.title}
          fullName={x.node.fullName}
          pricePerSession={x.node.sessionPricing}
          slug={x.node.slug}
          headline={x.node.headline.headline}
          profileImageSrc={x.node.profilePhoto.fixed.src}
        />
      </Col>
    ));

  return (
    <Layout contrast>
      <Container
        style={{
          backgroundImage: `linear-gradient(25deg, ${darken(
            0.15,
            theme.colors.secondary
          )}, ${lighten(0.1, theme.colors.secondary)})`,
        }}
        fluid
      >
        <Container>
          <Row>
            <Col
              className="d-block d-md-flex"
              style={{
                minHeight: theme.sizes[8],
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 0',
              }}
            >
              <Box>
                {cards.length === 2 ? (
                  <>
                    <Text
                      lineHeight="copy"
                      m={0}
                      fontFamily="heading"
                      fontWeight={3}
                      fontSize={2}
                      color={theme.colors.white}
                    >
                      {`${
                        userSnapshot.data().fullName.split(/\s/)[0]
                      }, here are your therapist matches`}
                    </Text>
                    <Text
                      lineHeight="copy"
                      m={0}
                      fontFamily="heading"
                      fontSize={0}
                      color={theme.colors.white}
                    >
                      It looks like we only have two matches for you at this
                      time, but if you remove some dealbreakers you’ll match
                      with more therapists. We’re also continuing to expand our
                      network of partner therapists.
                    </Text>
                  </>
                ) : cards.length === 1 ? (
                  <>
                    <Text
                      lineHeight="copy"
                      m={0}
                      fontFamily="heading"
                      fontWeight={3}
                      fontSize={2}
                      color={theme.colors.white}
                    >
                      {`${
                        userSnapshot.data().fullName.split(/\s/)[0]
                      }, here are your therapist matches`}
                    </Text>
                    <Text
                      lineHeight="copy"
                      m={0}
                      fontFamily="heading"
                      fontSize={0}
                      color={theme.colors.white}
                    >
                      It looks like we only have one/two match/es for you at
                      this time, but if you remove some dealbreakers you’ll
                      match with more therapists. We’re also continuing to
                      expand our network of partner therapists.
                    </Text>
                  </>
                ) : cards.length ? (
                  <Text
                    lineHeight="copy"
                    m={0}
                    fontFamily="heading"
                    fontWeight={3}
                    fontSize={2}
                    color={theme.colors.white}
                  >
                    {`${
                      userSnapshot.data().fullName.split(/\s/)[0]
                    }, here are your therapist matches`}
                  </Text>
                ) : (
                  <Text
                    lineHeight="copy"
                    m={0}
                    fontFamily="heading"
                    fontSize={0}
                    color={theme.colors.white}
                  >
                    It looks like we don’t have any therapists that perfectly
                    match your responses at the moment. We’re continuing to
                    expand our network of partner therapists but, in the
                    meantime, below are three therapists from our current
                    network.
                  </Text>
                )}
              </Box>
              <Button
                title="User Home"
                px={4}
                mt={[2, 0]}
                colors="whiteSecondary"
                onClick={() => {
                  navigate('/user');
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid style={{ backgroundColor: theme.colors.sand }}>
        <Container>
          <Row
            style={{
              paddingTop: theme.space[4],
              paddingBottom: theme.space[4],
            }}
          >
            {cards.length ? cards : randomTherapistCards}
          </Row>
          <Row className="pb-5">
            <Col xs="12" className="mb-2 text-center">
              <Button
                title="Back to my profile"
                onClick={async () => {
                  await navigate('/app/user');
                }}
                type="primary"
                px={3}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  );
};

export default MatchesAuth;
