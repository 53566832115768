import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import Layout from '../components/Layout';
import AccountPage from '../app-pages/account';
import LoginPage from '../app-pages/login';
import UserAuth from '../screens/auth/UserAuth';
import PrivateRoute from '../utils/PrivateRoute';
import getFirebase, {
  getFirebaseAuth,
  getFirebaseFirestore,
} from '../services/firebase';
import { useSession } from '../utils/firebase-hooks';
import SignupPage from '../app-pages/signup';
import EvaluationAuth from '../screens/auth/EvaluationAuth';
import MatchAuth from '../screens/auth/MatchAuth';
import MatchIntroPage from '../app-pages/match-intro';
import DiagnosisIntroPage from '../app-pages/diagnosis-intro';
import MatchesAuth from '../screens/auth/MatchesAuth';
import { Container } from 'reactstrap';
import useTimeout from '@rooks/use-timeout';
import { graphql, useStaticQuery } from 'gatsby';
import { AppQuery, Query } from '../graphql-types';
import Img, { FixedObject } from 'gatsby-image';
import TherapistAuth from '../screens/auth/TherapistAuth';
import { useAuthState } from 'react-firebase-hooks/auth';

const App = () => {
  // Orig: width: 771, height: 229 (0.297)
  const rawData = useStaticQuery<AppQuery>(graphql`
    query App {
      file(relativePath: { eq: "brand/helsa-pride-black.png" }) {
        childImageSharp {
          fixed(width: 135, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const [user, loading, error] = useAuthState(getFirebaseAuth());

  if (loading) {
    return (
      <Container
        fluid
        className="w-100 vh-100 d-flex flex-column justify-content-center align-items-center position-absolute"
        style={{ backgroundColor: '#3d8371' }}
      >
        <Img
          fixed={rawData.file.childImageSharp.fixed as FixedObject}
          alt="logo"
          style={{ marginBottom: 40 }}
        />
        <div className="sk-bounce">
          <div className="sk-bounce-dot bg-white" />
          <div className="sk-bounce-dot bg-white" />
        </div>
      </Container>
    );
  }

  return (
    <Router>
      <UserAuth path="/app/user" />
      <PrivateRoute component={MatchesAuth} path="/app/user/matches" />
      <PrivateRoute component={MatchAuth} path="/app/match" />
      <PrivateRoute component={MatchIntroPage} path="/app/match/intro" />
      <PrivateRoute
        component={TherapistAuth}
        path="/app/therapist/:therapistId"
      />
      <LoginPage path="/app/login" />
      <SignupPage path="/app/signup" />
      <EvaluationAuth path="/app/quiz/:category" />
      <DiagnosisIntroPage path="/app/quiz/intro" />
    </Router>
  );
};
export default App;
