import React, { useEffect, useState } from 'react';
import {
  graphql,
  Link,
  navigate as gatsbyNavigate,
  useStaticQuery,
} from 'gatsby';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import { RouteComponentProps, navigate } from '@reach/router';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Jumbotron,
  Row,
} from 'reactstrap';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {
  useCollectionDataOnce,
  useDocumentDataOnce,
  useDocumentOnce,
} from 'react-firebase-hooks/firestore';
import { useSession } from '../../utils/firebase-hooks';
import AccessibleActionText from '../../components/AccessibleActionText';
import WellbeingIndicator, {
  COLOR_A,
  COLOR_B,
  COLOR_C,
  COLOR_D,
} from '../../components/WellbeingIndicator';
import Img, { FixedObject } from 'gatsby-image';
import MainContainer from '../../components/MainContainer';
import { MatchResultsFirebase } from './MatchAuth';
import {
  ContentfulOverallWellbeing,
  ContentfulOverallWellbeingEdge,
  ContentfulTherapistEdge,
  MatchesQuery,
  UserQuery,
} from '../../graphql-types';
import {
  MatchTherapistCard,
  UserTherapistCard,
} from '../../components/TherapistCard';
import { getMatchesInfoForCards } from './MatchesAuth';
import WellbeingDiagnosisCopy from '../../components/WellbeingDiagnosisCopy';
import {
  getProfileIntroductionCopy,
  getTimeOfDayString,
} from '../../utils/profile';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth, getFirebaseFirestore } from '../../services/firebase';
import { theme } from '../../theme';
import Text from '../../reusecore/src/elements/Text/text';
import WellbeingRow from '../../components/WellbeingSpheres/WellbeingRow';
import { groupBy, intersection, pickBy, toPairs } from 'lodash-es';
import colors from '../../theme/colors';
import { darken, lighten } from 'polished';
import { PostGrid } from '../../components/Posts/style';
import PostCardModern from '../../components/PostCardModern/postCardModern';
import moment from 'moment';
import Box from '../../reusecore/src/elements/Box';
import Button from '../../reusecore/src/elements/Button/button';
import TestCard from '../../components/TestCard/TestCard';
import { articleRichTextOptions } from '../../utils/contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getOverallWellbeingScore } from '../../utils/diagnosis';
import {
  TherapyTypes,
  UserMatchingCriteria,
  UserMatchingRecommendation,
} from '../../utils/match';
import ClusterKey from '../../components/WellbeingSpheres/ClusterKey';

export interface UserPageProps extends RouteComponentProps {}

export interface WellbeingResultsFirebase {
  responses: {
    questionNumber: number;
    score: number;
  }[];
  creationDate: string;
  severityLevel: number;
}

const UserAuth: React.FunctionComponent<UserPageProps> = (): JSX.Element => {
  const {
    allContentfulTherapist: { edges: therapistData },
    allContentfulArticle,
    allContentfulOverallWellbeing: { edges: overallWellbeingData },
    allContentfulUserDashboard: { edges: userDashboardData },
  } = useStaticQuery<UserQuery>(graphql`
    query User {
      allContentfulTherapist {
        edges {
          node {
            slug
            therapyTypes
            specialisms
            dateOfBirth
            sexuality
            gender
            ethnicity
            sessionPricing
            profilePhoto {
              fixed(width: 600, height: 400) {
                src
              }
            }
            fullName
            headline {
              headline
            }
            title
          }
        }
      }
      allContentfulArticle(filter: { featured: { eq: true } }, limit: 2) {
        edges {
          node {
            id
            slug
            title
            subtitle
            publishedDate
            coverImage {
              fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
      allContentfulOverallWellbeing {
        edges {
          node {
            scoreId
            diagnosisCopy {
              json
            }
          }
        }
      }
      allContentfulUserDashboard {
        edges {
          node {
            anxietyCardImage {
              fluid {
                src
              }
            }
            depressionCardImage {
              fluid {
                src
              }
            }
            minorityStressCardImage {
              fluid {
                src
              }
            }
            stressCardImage {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `);

  const overallSummariesObject = groupBy(overallWellbeingData, 'node.scoreId');

  const articles = allContentfulArticle.edges.map<JSX.Element>(x => (
    <PostGrid>
      <PostCardModern
        key={x.node.slug}
        title={x.node.title}
        image={x.node.coverImage.fluid}
        imageType={'fluid'}
        url={`/article/${x.node.slug}`}
        description={x.node.subtitle}
        date={moment(x.node.publishedDate).format('D MMM YYYY')}
        placeholderBG={'white'}
      />
    </PostGrid>
  ));

  const [user, loading, error] = useAuthState(getFirebaseAuth());

  const db = getFirebaseFirestore();

  const [emailVerifiedAlert, setEmailVerifiedAlert] = useState<boolean>(false);
  const [newVerificationEmailSent, setNewVerificationEmailSent] = useState<
    boolean
  >(false);

  useEffect(() => {
    if (!user.emailVerified) {
      setEmailVerifiedAlert(true);
    }
  }, []);

  const [userSnapshot, userLoading, userError] = useDocumentOnce(
    !db ? null : db.collection('users').doc(user.uid)
  );
  const [anxietySnapshot, anxietyLoading, anxietyError] = useCollectionDataOnce<
    WellbeingResultsFirebase
  >(
    !db
      ? null
      : db
          .collection('users')
          .doc(user.uid)
          .collection('wellbeingResults')
          .doc('anxiety')
          .collection('categoryResponses')
          .orderBy('creationDate', 'desc')
  );
  const [
    depressionSnapshot,
    depressionLoading,
    depressionError,
  ] = useCollectionDataOnce<WellbeingResultsFirebase>(
    !db
      ? null
      : db
          .collection('users')
          .doc(user.uid)
          .collection('wellbeingResults')
          .doc('depression')
          .collection('categoryResponses')
          .orderBy('creationDate', 'desc')
  );
  const [stressSnapshot, stressLoading, stressError] = useCollectionDataOnce<
    WellbeingResultsFirebase
  >(
    !db
      ? null
      : db
          .collection('users')
          .doc(user.uid)
          .collection('wellbeingResults')
          .doc('stress')
          .collection('categoryResponses')
          .orderBy('creationDate', 'desc')
  );
  const [
    minoritySnapshot,
    minorityLoading,
    minorityError,
  ] = useCollectionDataOnce<WellbeingResultsFirebase>(
    !db
      ? null
      : db
          .collection('users')
          .doc(user.uid)
          .collection('wellbeingResults')
          .doc('minority')
          .collection('categoryResponses')
          .orderBy('creationDate', 'desc')
  );

  const [matchesSnapshot, matchesLoading, matchesError] = useCollectionDataOnce<
    MatchResultsFirebase
  >(
    !db
      ? null
      : db
          .collection('users')
          .doc(user.uid)
          .collection('matchResults')
          .orderBy('creationDate', 'desc')
  );

  const sendNewVerificationEmail = async (): Promise<void> => {
    try {
      await user.sendEmailVerification();
      setNewVerificationEmailSent(true);
    } catch (e) {
      console.error(e);
    }
  };

  if (
    userLoading ||
    anxietyLoading ||
    depressionLoading ||
    stressLoading ||
    minorityLoading ||
    matchesLoading
  ) {
    return (
      <Container
        fluid
        className="w-100 vh-100 d-flex flex-column justify-content-center align-items-center position-absolute"
        style={{ backgroundColor: '#3d8371' }}
      >
        <div className="sk-bounce">
          <div className="sk-bounce-dot bg-white" />
          <div className="sk-bounce-dot bg-white" />
        </div>
      </Container>
    );
  }

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]');
  }

  const getContextualCopy = (): string => {
    const wellbeingTestsCompleted: number = [
      anxietySnapshot.length,
      depressionSnapshot.length,
      stressSnapshot.length,
      minoritySnapshot.length,
    ].filter(x => !!x).length;

    if (wellbeingTestsCompleted === 0) {
      return (
        'How about we get started on the wellbeing tests below, to help' +
        ' you understand your current mental health better?'
      );
    } else if (wellbeingTestsCompleted < 4) {
      return (
        'Do you want to complete the outstanding wellbeing tests today' +
        ' so you can receive your overall wellbeing summary? '
      );
    } else if (!!matchesSnapshot.length) {
      return (
        'What would you like to do today: retake wellbeing tests, match with' +
        ' therapists or read our latest articles? '
      );
    } else {
      return (
        'What would you like to do today: retake wellbeing tests, rematch' +
        ' with therapists or read our latest articles? '
      );
    }
  };

  let cards;
  if (matchesSnapshot.length) {
    const [cardsInfo, noMatches] = getMatchesInfoForCards(
      matchesSnapshot[0] as MatchResultsFirebase,
      therapistData as ContentfulTherapistEdge[]
    );

    const recommendations: UserMatchingRecommendation = matchesSnapshot[0]
      .recommendations || {
      specialisms: [],
      therapies: [],
      distance: { value: null, dealbreaker: null },
      pricing: { value: null, dealbreaker: null },
      demographic: {
        age: { value: [], dealbreaker: false },
        ethnicity: { value: [], dealbreaker: false },
        gender: { value: [], dealbreaker: false },
        sexuality: { value: [], dealbreaker: false },
      },
    };

    cards = cardsInfo
      .sort((a, b) => b.score - a.score)
      .map(x => (
        <Col key={x.slug} xs={12} lg={4} style={{ display: 'flex' }}>
          <MatchTherapistCard
            title={x.title}
            distanceMiles={x.distanceMiles}
            fullName={x.fullName}
            pricePerSession={x.pricePerSession}
            score={x.score}
            slug={x.slug}
            headline={x.headline}
            profileImageSrc={x.profileImageSrc}
            therapyTypes={intersection(
              x.therapyTypes,
              recommendations.therapies
            )}
            specialisms={intersection(
              x.specialisms,
              recommendations.specialisms
            )}
            demographics={[
              ...intersection(recommendations.demographic.sexuality.value, [
                x.sexuality,
              ]),
              ...intersection(recommendations.demographic.gender.value, [
                x.gender,
              ]),
              ...intersection(recommendations.demographic.ethnicity.value, [
                x.ethnicity,
              ]),
            ]}
          />
        </Col>
      ));
  }

  const noQuizzesTaken: boolean =
    !depressionSnapshot.length &&
    !stressSnapshot.length &&
    !anxietySnapshot.length &&
    !minoritySnapshot.length;
  const allQuizzesTaken: boolean =
    !!depressionSnapshot.length &&
    !!stressSnapshot.length &&
    !!anxietySnapshot.length &&
    !!minoritySnapshot.length;

  return (
    <Layout contrast barsOnly>
      <Container
        style={{
          backgroundImage: `linear-gradient(25deg, ${darken(
            0.1,
            theme.colors.primary
          )}, ${lighten(0.05, theme.colors.primary)})`,
        }}
        fluid
      >
        <Container>
          <Row>
            <Col
              className="d-flex"
              style={{
                minHeight: theme.sizes[7],
                paddingTop: theme.sizes[1],
                paddingBottom: theme.sizes[1],
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Text
                lineHeight="copy"
                m={0}
                fontFamily="heading"
                fontWeight={3}
                fontSize={2}
                color={theme.colors.white}
              >
                Good {getTimeOfDayString()}{' '}
                {userSnapshot.data().fullName.split(/\s/)[0]}
              </Text>
              <Text
                lineHeight="copy"
                m={0}
                fontFamily="heading"
                fontSize={0}
                color={theme.colors.white}
              >
                {getContextualCopy()}
              </Text>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid style={{ backgroundColor: theme.colors.sand }}>
        <Container>
          <Row
            style={{
              paddingTop: theme.space[4],
            }}
          >
            <Col>
              <Text
                as="h2"
                lineHeight="copy"
                m={0}
                fontFamily="heading"
                fontWeight={3}
                fontSize={5}
                py={4}
              >
                Your Wellbeing Indicator
              </Text>
            </Col>
          </Row>
          <Row
            style={{
              paddingTop: theme.space[4],
              paddingBottom: theme.space[4],
            }}
          >
            <Col className="d-none d-sm-block">
              <WellbeingRow
                linkToResults
                anxiety={{
                  severity: anxietySnapshot.length
                    ? anxietySnapshot[0].severityLevel
                    : 1,
                  animated: true,
                  unknown: !anxietySnapshot.length,
                  faded: !anxietySnapshot.length,
                }}
                depression={{
                  severity: depressionSnapshot.length
                    ? depressionSnapshot[0].severityLevel
                    : 1,
                  animated: true,
                  unknown: !depressionSnapshot.length,
                  faded: !depressionSnapshot.length,
                }}
                stress={{
                  severity: stressSnapshot.length
                    ? stressSnapshot[0].severityLevel
                    : 1,
                  animated: true,
                  unknown: !stressSnapshot.length,
                  faded: !stressSnapshot.length,
                }}
                minorityStress={{
                  severity: minoritySnapshot.length
                    ? minoritySnapshot[0].severityLevel
                    : 1,
                  animated: true,
                  unknown: !minoritySnapshot.length,
                  faded: !minoritySnapshot.length,
                }}
              />
            </Col>
            <Col className="d-block d-sm-none">
              <WellbeingRow
                scale={0.06}
                noKey
                linkToResults
                anxiety={{
                  severity: anxietySnapshot.length
                    ? anxietySnapshot[0].severityLevel
                    : 1,
                  animated: true,
                  unknown: !anxietySnapshot.length,
                  faded: !anxietySnapshot.length,
                }}
                depression={{
                  severity: depressionSnapshot.length
                    ? depressionSnapshot[0].severityLevel
                    : 1,
                  animated: true,
                  unknown: !depressionSnapshot.length,
                  faded: !depressionSnapshot.length,
                }}
                stress={{
                  severity: stressSnapshot.length
                    ? stressSnapshot[0].severityLevel
                    : 1,
                  animated: true,
                  unknown: !stressSnapshot.length,
                  faded: !stressSnapshot.length,
                }}
                minorityStress={{
                  severity: minoritySnapshot.length
                    ? minoritySnapshot[0].severityLevel
                    : 1,
                  animated: true,
                  unknown: !minoritySnapshot.length,
                  faded: !minoritySnapshot.length,
                }}
              />
              <ClusterKey style={{ marginTop: 10 }} mobile />
            </Col>
          </Row>
          {allQuizzesTaken && (
            <Row>
              <Col
                sm={{ size: 12 }}
                bg={theme.colors.white}
                style={{ paddingBottom: theme.space[4] }}
              >
                <Box bg="white" width="100%" borderRadius={3}>
                  <Text
                    m={0}
                    fontSize={2}
                    fontWeight={3}
                    pt={3}
                    pb={1}
                    textAlign="center"
                  >
                    Overall Wellbeing Summary
                  </Text>
                  <hr
                    style={{
                      margin: '0 2rem 1rem 2rem',
                      borderColor: theme.colors.lightGrey,
                    }}
                  />
                  <Text px={4} pb={3} textAlign="center">
                    {documentToReactComponents(
                      overallSummariesObject[
                        `score-${getOverallWellbeingScore([
                          anxietySnapshot[0].severityLevel,
                          depressionSnapshot[0].severityLevel,
                          stressSnapshot[0].severityLevel,
                          minoritySnapshot[0].severityLevel,
                        ])}`
                      ][0].node.diagnosisCopy.json,
                      articleRichTextOptions
                    )}
                  </Text>
                </Box>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={6} style={{ marginBottom: theme.space[4] }}>
              <TestCard
                title={'Anxiety Test'}
                gradientColours={[
                  darken(0.1, theme.colors.anxiety),
                  lighten(0.05, theme.colors.anxiety),
                ]}
                imageUrl={userDashboardData[0].node.anxietyCardImage.fluid.src}
                factsheetUrl="/article/lgbt-anxiety-fact-sheet"
                completed={!!anxietySnapshot.length}
                onClick={() => {
                  navigate('/evaluation/anxiety');
                }}
              />
            </Col>
            <Col sm={6} style={{ marginBottom: theme.space[4] }}>
              <TestCard
                title={'Depression Test'}
                gradientColours={[
                  darken(0.1, theme.colors.depression),
                  lighten(0.05, theme.colors.depression),
                ]}
                imageUrl={
                  userDashboardData[0].node.depressionCardImage.fluid.src
                }
                factsheetUrl="/article/lgbt-depression-fact-sheet"
                completed={!!depressionSnapshot.length}
                onClick={() => {
                  navigate('/evaluation/depression');
                }}
              />
            </Col>
            <Col sm={6} style={{ marginBottom: theme.space[4] }}>
              <TestCard
                title={'Stress Test'}
                gradientColours={[
                  darken(0.1, theme.colors.stress),
                  lighten(0.05, theme.colors.stress),
                ]}
                imageUrl={userDashboardData[0].node.stressCardImage.fluid.src}
                factsheetUrl="/article/lgbt-stress-fact-sheet"
                completed={!!stressSnapshot.length}
                onClick={() => {
                  navigate('/evaluation/stress');
                }}
              />
            </Col>
            <Col sm={6} style={{ marginBottom: theme.space[4] }}>
              <TestCard
                title={'Minority Stress Test'}
                gradientColours={[
                  darken(0.1, theme.colors.minorityStress),
                  lighten(0.05, theme.colors.minorityStress),
                ]}
                imageUrl={
                  userDashboardData[0].node.minorityStressCardImage.fluid.src
                }
                factsheetUrl="/article/lgbt-minority-stress-fact-sheet"
                completed={!!minoritySnapshot.length}
                onClick={() => {
                  navigate('/evaluation/minority');
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container>
          <Row
            style={{
              paddingTop: theme.space[4],
            }}
          >
            <Col>
              <div id="matches">
                <Text
                  as="h2"
                  lineHeight="normal"
                  m={0}
                  fontFamily="heading"
                  fontWeight={3}
                  fontSize={5}
                  pt={4}
                  pb={2}
                >
                  Your Therapist Matches
                </Text>
              </div>
              {(!cards || !cards.length) && (
                <Text lineHeight="copy" m={0} fontFamily="body" fontSize={0}>
                  This section will display your top three LGBTQ+ specialised
                  partner therapists once you have completed the matching
                  questionnaire.
                </Text>
              )}
            </Col>
          </Row>
          {!!cards && !!cards.length && (
            <Row
              style={{
                paddingTop: theme.space[4],
                paddingBottom: theme.space[4],
              }}
            >
              {cards}
            </Row>
          )}
          <Row>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                title="Match Me"
                colors="primary"
                onClick={() => {
                  navigate('/match');
                }}
                my={5}
                px={4}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid style={{ backgroundColor: theme.colors.sand }}>
        <Container>
          <Row
            style={{
              paddingTop: theme.space[4],
            }}
          >
            <Col>
              <Text
                as="h2"
                lineHeight="copy"
                m={0}
                fontFamily="heading"
                fontWeight={3}
                fontSize={5}
                py={4}
              >
                Your Featured Articles
              </Text>
            </Col>
          </Row>
          <Row>{articles}</Row>
        </Container>
      </Container>
    </Layout>
  );
};

export default UserAuth;
