import React from 'react';
import styled from 'styled-components';
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import { useFormik } from 'formik';
import { AwesomeButton } from 'react-awesome-button';

export interface LoginFormProps {
  onSubmit: (values: LoginFormParameters) => void;
}

export interface LoginFormParameters {
  email: string;
  password: string;
}

const LoginForm: React.FunctionComponent<LoginFormProps> = ({
  onSubmit,
}): JSX.Element => {
  const formik = useFormik<LoginFormParameters>({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="password">Password</Label>
        <Input
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
      </FormGroup>
      <AwesomeButton type="primary">Submit</AwesomeButton>
    </Form>
  );
};

export default LoginForm;
