// @TODO: Add contextual introduction copy cases.
export const getProfileIntroductionCopy = (
  anxiety: boolean,
  depression: boolean,
  stress: boolean,
  minorityStress: boolean
): string => {
  return 'Contextual introduction copy.';
};

export const getTimeOfDayString = (): string => {
  const today = new Date();
  const curHr = today.getHours();

  if (curHr < 12) {
    return 'morning';
  } else if (curHr < 18) {
    return 'afternoon';
  } else {
    return 'evening';
  }
};
