export const getOverallWellbeingScore = (
  scoresArray: [number, number, number, number]
): number => {
  if (scoresArray.some(x => x === null)) return null;

  if (scoresArray.every(x => x === 4)) {
    return 7;
  }
  if (scoresArray.filter(x => x === 4).length >= 2) {
    return 6;
  }
  if (scoresArray.some(x => x === 4)) {
    return 5;
  }
  if (scoresArray.some(x => x === 3)) {
    return 4;
  }
  if (scoresArray.filter(x => x === 2).length >= 2) {
    return 3;
  }
  if (scoresArray.some(x => x === 2)) {
    return 2;
  }
  if (scoresArray.every(x => x === 1)) {
    return 1;
  }

  throw Error('Case not handled.');
};
