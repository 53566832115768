import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { Button, Col, Container, Row } from 'reactstrap';
import LoginForm, { LoginFormParameters } from '../components/LoginForm';
import getFirebase from '../services/firebase';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import axios from 'axios';
import { AwesomeButton } from 'react-awesome-button';
import BackgroundImage, { IFluidObject } from 'gatsby-background-image';
import { SignupQuery } from '../graphql-types';
import {
  StageOneSignupForm,
  StageOneSignupFormParameters,
} from '../components/SignupForm/StageOneSignupForm';
import {
  StageTwoSignupForm,
  StageTwoSignupFormParameters,
} from '../components/SignupForm/StageTwoSignupForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import useTimeout from '@rooks/use-timeout';

export interface SignupPageProps extends RouteComponentProps {}

export enum SignupStage {
  INITIAL,
  INFO,
  PROCESSING,
  COMPLETE,
}

interface UserDetailAttributes
  extends StageOneSignupFormParameters,
    StageTwoSignupFormParameters {}

const SignupPage: React.FunctionComponent<SignupPageProps> = (): JSX.Element => {
  const rawData = useStaticQuery<SignupQuery>(graphql`
    query Signup {
      file(relativePath: { eq: "covers/forest.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [stage, setStage] = useState<SignupStage>(SignupStage.INITIAL);
  const [userDetails, setUserDetails] = useState<UserDetailAttributes>({
    email: null,
    age: null,
    fullName: null,
    password: null,
    postcode: null,
  });
  const [timeoutWaiting, setTimeoutWaiting] = useState<boolean>(true);
  const { start } = useTimeout(() => {
    setTimeoutWaiting(false);
  }, 2000);

  useEffect(() => {
    if (stage === SignupStage.COMPLETE && timeoutWaiting) {
      start();
    }
    if (!timeoutWaiting) {
      navigate('/app/user');
    }
  }, [start, timeoutWaiting, stage]);

  const handleSubmit = async (values: UserDetailAttributes): Promise<void> => {
    try {
      setFormLoading(true);
      const postcodeResponse = await axios.get<{
        result: { latitude: number; longitude: number };
      }>(
        `https://api.postcodes.io/postcodes/${values.postcode.replace(
          /\s/g,
          ''
        )}`
      );
      if (
        postcodeResponse.status !== 200 ||
        !postcodeResponse.data.result.latitude
      ) {
        throw new Error('Postcode could not be found.');
      }
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const creds = await firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password);
      await creds.user.sendEmailVerification();
      await firebase
        .firestore()
        .collection('users')
        .doc(creds.user.uid)
        .set({
          email: creds.user.email,
          fullName: values.fullName,
          lat: postcodeResponse.data.result.latitude,
          long: postcodeResponse.data.result.longitude,
          postcode: values.postcode,
          age: values.age,
        });
      setFormLoading(false);
    } catch (e) {
      setFormLoading(false);
    }
  };

  const handleStageOne = (values: StageOneSignupFormParameters): void => {
    setUserDetails({
      ...userDetails,
      email: values.email,
      password: values.password,
      fullName: values.fullName,
    });
    setStage(SignupStage.INFO);
  };

  const handleStageTwo = async (
    values: StageTwoSignupFormParameters
  ): Promise<void> => {
    setUserDetails({
      ...userDetails,
      age: values.age,
      postcode: values.postcode,
    });
    setStage(SignupStage.PROCESSING);
  };

  useEffect(() => {
    if (stage === SignupStage.PROCESSING) {
      handleSubmit(userDetails)
        .then(() => {
          setStage(SignupStage.COMPLETE);
        })
        .catch(e => {
          //TODO: Either get rid of this or add some proper error handling, SMH.
          console.log(e);
        });
    }
  }, [stage, userDetails]);

  if (formLoading) {
    return (
      <Container
        fluid
        className="w-100 vh-100 px-0 d-flex flex-column justify-content-center align-items-center position-absolute"
        style={{ backgroundColor: '#3d8371' }}
      >
        <div className="sk-bounce">
          <div className="sk-bounce-dot bg-white" />
          <div className="sk-bounce-dot bg-white" />
        </div>
      </Container>
    );
  }

  return (
    <Layout>
      <BackgroundImage
        fluid={rawData.file.childImageSharp.fluid as IFluidObject}
        className="vh-100 w-100 position-absolute"
      />
      <Container fluid className="vh-100 w-100 px-0 position-relative">
        <div className="h-100 w-100 d-flex flex-column justify-content-center position-absolute">
          <Container className="text-center">
            <Row className="mb-4">
              <Col>
                {stage === SignupStage.INITIAL && (
                  <>
                    <h1>Sign Up</h1>
                    <StageOneSignupForm onSubmit={handleStageOne} />
                  </>
                )}
                {stage === SignupStage.INFO && (
                  <>
                    <h1>Sign Up</h1>
                    <StageTwoSignupForm onSubmit={handleStageTwo} />
                  </>
                )}
                {stage === SignupStage.COMPLETE && (
                  <>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color="black"
                      size="5x"
                      className="mb-3"
                    />
                    <h4>Welcome to Helsa</h4>
                  </>
                )}
              </Col>
            </Row>
            {stage === SignupStage.INITIAL && (
              <Row>
                <Col>
                  <AwesomeButton
                    type="secondary"
                    onPress={() => {
                      navigate('/app/login');
                    }}
                  >
                    Already have an account?
                  </AwesomeButton>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default SignupPage;
