import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Unsubscribe, User } from 'firebase';
import { isBrowser } from '../services/auth';
import getFirebase from '../services/firebase';

// Firebase user context - initialise as null
export const UserContext = React.createContext<AuthState>({
  initializing: true,
  user: null,
});

// This hook allows us to access our context (Consumes).
export const useSession = (): AuthState => {
  return useContext<AuthState>(UserContext);
};

export interface AuthState {
  user: User;
  initializing: boolean;
}

// This hooks monitors whether auth has occurred to feed it into the context (Provides).
export const useAuth = (): AuthState => {
  // Lazy state initialisation.
  const [state, setState] = useState(() => {
    // Non-browser escape
    if (!isBrowser()) {
      return { initializing: false, user: null };
    }
    const user: User = firebase.auth().currentUser;
    return { initializing: !user, user };
  });
  const onChange = user => {
    setState({ initializing: false, user });
  };

  useEffect(() => {
    // listen for auth state changes
    const unsubscribe: Unsubscribe = firebase
      .auth()
      .onAuthStateChanged(onChange);
    // unsubscribe to the listener when unmounting
    return (): void => {
      unsubscribe();
    };
  }, []);

  return state;
};

export const UserProvider: React.FunctionComponent<{}> = ({ children }) => {
  getFirebase();
  const currentAuthState: AuthState = useAuth();
  return (
    <UserContext.Provider value={currentAuthState}>
      {children}
    </UserContext.Provider>
  );
};
