import React from 'react';
import { Card, CardBody, CardImg } from 'reactstrap';
import { theme } from '../../theme';
import { darken, lighten } from 'polished';
import Text from '../../reusecore/src/elements/Text/text';
import Button from '../../reusecore/src/elements/Button/button';
import Box from '../../reusecore/src/elements/Box';
import { Link } from 'gatsby';

export interface TestCardProps {
  title: string;
  gradientColours: [string, string];
  imageUrl: string;
  completed: boolean;
  onClick: () => void;
  factsheetUrl: string;
}

const TestCard: React.FunctionComponent<TestCardProps> = ({
  title,
  completed,
  gradientColours,
  imageUrl,
  factsheetUrl,
  onClick,
}): JSX.Element => {
  return (
    <Card>
      <CardImg
        style={{
          height: theme.sizes[10],
          width: '100%',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          objectFit: 'cover',
        }}
        src={imageUrl}
      />
      <CardBody
        style={{
          display: 'flex',
          backgroundImage: `linear-gradient(25deg, ${gradientColours[0]}, ${gradientColours[1]})`,
          borderBottomLeftRadius: theme.radii[3],
          borderBottomRightRadius: theme.radii[3],
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box flexBox flexDirection="column">
          <Text
            fontFamily="heading"
            fontSize={1}
            fontWeight={3}
            color={theme.colors.white}
            m={0}
          >
            {title}
          </Text>
          <Link to={factsheetUrl}>
            <Text
              fontFamily="body"
              fontSize={0}
              fontWeight={0}
              m={0}
              color="white"
              style={{ textDecoration: 'underline' }}
            >
              Factsheet
            </Text>
          </Link>
        </Box>
        <Button
          title={completed ? 'Retake' : 'Start'}
          px={4}
          colors="whiteSecondary"
          onClick={onClick}
        />
      </CardBody>
    </Card>
  );
};

export default TestCard;
