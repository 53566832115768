import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Button, Col, Container, Row } from 'reactstrap';
import { RouteComponentProps } from '@reach/router';
import { Categories } from '../screens/auth/EvaluationAuth';
import Layout from '../components/Layout';

export interface DiagnosisIntroPageProps extends RouteComponentProps {}

const ScreenPlaceholder = styled.div`
  height: 300px;
  background-color: gray;
`;

const DiagnosisIntroPage: React.FunctionComponent<DiagnosisIntroPageProps> = (): JSX.Element => {
  return (
    <Layout>
      <Container>
        <Row>
          <Col className="text-center">
            <h1>Match Home</h1>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex">
            <ScreenPlaceholder />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column text-center">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <Button
              color="primary"
              onClick={() => {
                navigate('/app/quiz/anxiety');
              }}
            >
              Start Anxiety
            </Button>
            <Button
              color="primary"
              onClick={() => {
                navigate('/app/quiz/depression');
              }}
            >
              Start Depression
            </Button>
            <Button
              color="primary"
              onClick={() => {
                navigate('/app/quiz/stress');
              }}
            >
              Start Stress
            </Button>
            <Button
              color="primary"
              onClick={() => {
                navigate('/app/quiz/minority');
              }}
            >
              Start Minority Stress
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default DiagnosisIntroPage;
