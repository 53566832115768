import React from 'react';
import styled from 'styled-components';
import { Button, Col, Container, Row } from 'reactstrap';
import { navigate, RouteComponentProps } from '@reach/router';
import Layout from '../components/Layout';

export interface MatchIntroPageProps extends RouteComponentProps {}

const ScreenPlaceholder = styled.div`
  height: 300px;
  background-color: gray;
`;

const MatchIntroPage: React.FunctionComponent<MatchIntroPageProps> = (): JSX.Element => {
  return (
    <Layout>
      <Container>
        <Row>
          <Col className="text-center">
            <h1>Match Home</h1>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex">
            <ScreenPlaceholder />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex text-center flex-column">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <Button
              color="primary"
              onClick={() => {
                navigate('/app/match');
              }}
            >
              Start Now
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default MatchIntroPage;
