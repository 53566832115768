import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { Button, Col, Container, Row } from 'reactstrap';
import LoginForm, { LoginFormParameters } from '../components/LoginForm';
import getFirebase from '../services/firebase';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { Redirect, RouteComponentProps } from '@reach/router';
import BackgroundImage, { IFluidObject } from 'gatsby-background-image';
import { useSession } from '../utils/firebase-hooks';
import { AwesomeButton } from 'react-awesome-button';
import Img, { FixedObject } from 'gatsby-image';
import { LoginQuery } from '../graphql-types';

export interface LoginPageProps extends RouteComponentProps {}

const LoginPage: React.FunctionComponent<LoginPageProps> = (): JSX.Element => {
  const rawData = useStaticQuery<LoginQuery>(graphql`
    query Login {
      file(relativePath: { eq: "covers/forest.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [formLoading, setFormLoading] = useState<boolean>(false);
  const { user } = useSession();

  const handleLogin = (values: LoginFormParameters) => {
    setFormLoading(true);
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then<void>(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(values.email, values.password)
          .then<void>(() => {
            setFormLoading(false);
            navigate('/app/user');
          })
          .catch(e => {
            setFormLoading(false);
            console.error(e);
          });
      });
  };

  if (formLoading) {
    return (
      <Container
        fluid
        className="w-100 vh-100 px-0 d-flex flex-column justify-content-center align-items-center position-absolute"
        style={{ backgroundColor: '#3d8371' }}
      >
        <div className="sk-bounce">
          <div className="sk-bounce-dot bg-white" />
          <div className="sk-bounce-dot bg-white" />
        </div>
      </Container>
    );
  }

  return (
    <Layout>
      <BackgroundImage
        fluid={rawData.file.childImageSharp.fluid as IFluidObject}
        className="vh-100 w-100 position-absolute"
      />
      <Container fluid className="vh-100 w-100 px-0 position-relative">
        <div className="h-100 w-100 d-flex flex-column justify-content-center position-absolute">
          <Container className="text-center">
            <Row className="mb-4">
              <Col>
                <h1 style={{ fontFamily: 'Montserrat' }}>Login</h1>
                <LoginForm onSubmit={handleLogin} />
              </Col>
            </Row>
            <Row>
              <Col>
                <AwesomeButton
                  type="secondary"
                  onPress={() => {
                    navigate('/app/signup');
                  }}
                >
                  Don't have an account?
                </AwesomeButton>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default LoginPage;
