import * as Yup from 'yup';
import { postcodeYupString } from '../../utils/registration';
import moment from 'moment';
import React from 'react';
import { useFormik } from 'formik';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { MainButton } from '../Button';

export interface StageOneSignupFormProps {
  onSubmit: (values: any) => void;
}

export interface StageOneSignupFormParameters {
  email: string;
  password: string;
  fullName: string;
}

const stageOneSignupFormValidationSchema = Yup.object().shape<
  StageOneSignupFormParameters
>({
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required(),
  password: Yup.string().required(),
  fullName: Yup.string().required(),
});
export const StageOneSignupForm: React.FunctionComponent<StageOneSignupFormProps> = ({
  onSubmit,
}): JSX.Element => {
  const formik = useFormik<StageOneSignupFormParameters>({
    initialValues: {
      email: '',
      password: '',
      fullName: '',
    },
    onSubmit,
    validationSchema: stageOneSignupFormValidationSchema,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="password">Password</Label>
        <Input
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="fullName">Full Name</Label>
        <Input
          name="fullName"
          id="fullName"
          placeholder="Full Name"
          value={formik.values.fullName}
          onChange={formik.handleChange}
        />
      </FormGroup>
      <MainButton type="primary">Submit</MainButton>
    </Form>
  );
};
