import React, { Component } from 'react';
import { isLoggedIn } from '../services/auth';
import { Redirect, RouteComponentProps } from '@reach/router';

export interface PrivateRouteProps extends RouteComponentProps {
  component: React.FunctionComponent;
}

// eslint-disable-next-line no-shadow
const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  // eslint-disable-next-line no-shadow
  component: Component,
  location,
  ...rest
}) => {
  // @ts-ignore
  if (!isLoggedIn() && location.pathname !== `/app/login`) {
    return <Redirect to="/app/login" />;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
