import * as Yup from 'yup';
import { postcodeYupString } from '../../utils/registration';
import moment from 'moment';
import React from 'react';
import { useFormik } from 'formik';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { MainButton } from '../Button';
import ReactDatePicker from 'react-datepicker';

export interface StageTwoSignupFormProps {
  onSubmit: (values: any) => void;
}

export interface StageTwoSignupFormParameters {
  postcode: string;
  age: number;
}

const stageTwoSignupFormValidationSchema = Yup.object().shape<
  StageTwoSignupFormParameters
>({
  postcode: postcodeYupString.required(),
  age: Yup.number()
    .positive()
    .max(120)
    .required(),
});
export const StageTwoSignupForm: React.FunctionComponent<StageTwoSignupFormProps> = ({
  onSubmit,
}): JSX.Element => {
  const formik = useFormik<StageTwoSignupFormParameters>({
    initialValues: {
      postcode: '',
      age: null,
    },
    onSubmit,
    validationSchema: stageTwoSignupFormValidationSchema,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <Label for="postcode">Postcode</Label>
        <Input
          name="postcode"
          id="postcode"
          placeholder="Postcode"
          value={formik.values.postcode}
          onChange={formik.handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="age">Age</Label>
        <Input
          name="age"
          id="age"
          placeholder="Age"
          type="number"
          value={formik.values.age}
          onChange={formik.handleChange}
        />
      </FormGroup>
      <MainButton type="primary">Submit</MainButton>
    </Form>
  );
};
